import React from 'react'

import CommunityModifyComponents from '../../../components/community/board/BoardModifyComponents'
import BoardModifyComponents from '../../../components/community/board/BoardModifyComponents'


const BoardModifyPage = () => {
    console.log('Board modify ')
    return (
        <div>
            <BoardModifyComponents/>
        </div>
    )
}

export default BoardModifyPage