import React from 'react'

import MarketAddComponents from '../../../components/community/market/MarketAddComponents'
import MarketModifyComponents from '../../../components/community/market/MarketModifyComponents'



const MarketModifyPage = () => {
    console.log('MarketModifyPage')
    return (
        <div>
            
            <MarketModifyComponents />
      
        </div>
    )
}

export default MarketModifyPage