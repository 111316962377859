import React from 'react'

import MarketAddComponents from '../../../components/community/market/MarketAddComponents'



const MarketAddPage = () => {
    console.log('market add ')
    return (
        <div>
            
            <MarketAddComponents />
      
        </div>
    )
}

export default MarketAddPage