import React from 'react'


import AnnounceAddComponents from '../../../components/community/announce/AnnounceAddComponents'



const AnnounceAddPage = () => {
    console.log('Announce add ')
    return (
        <div>
            
            <AnnounceAddComponents />
      
        </div>
    )
}

export default AnnounceAddPage