import React from 'react'


import AnnounceModifyComponents from '../../../components/community/announce/AnnounceModifyComponents'


const AnnounceModifyPage = () => {
    console.log('Announce. modify ')
    return (
        <div>
            <AnnounceModifyComponents/>
        </div>
    )
}

export default AnnounceModifyPage