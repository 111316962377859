import React from 'react'
import ChatComponents from '../../../components/community/chat/ChatComponents'



const ChatPage = () => {
    console.log('chat room ')
    return (
        <div>
            <ChatComponents />

        </div>
    )
}

export default ChatPage