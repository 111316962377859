import React from 'react'

import MarketListComponents from '../../../components/community/market/MarketListComponents'



const MarketListPage = () => {
    console.log('market list ')
    return (
        <div>
            <MarketListComponents/>

        </div>
    )
}

export default MarketListPage