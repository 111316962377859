import React from 'react'
import CommunityListComponent from '../../../components/community/board/BoardListComponents'
import BoardListComponents from '../../../components/community/board/BoardListComponents'



const BoardListPage = () => {
    console.log('Board list ')
    return (
        <div>
            <BoardListComponents/>

        </div>
    )
}

export default BoardListPage